import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import Enumerable from 'linq';
import { isNullOrUndefined } from 'util';
import { CallsAnalisisService } from '../../services/calls-analisis.service';
import { CorteDiarioModel } from '../corte-diario.models';
import { GraphGridSwitchComponent } from '../graph-grid-switch/graph-grid-switch.component';
import { ModalComparacionComponent } from '../modal-comparacion/modal-comparacion.component';
import { ModalGestionesComponent } from '../modal-gestiones/modal-gestiones.component';
import { ModalGraphComparacionComponent } from '../modal-graph-comparacion/modal-graph-comparacion.component';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';

@Component({
  selector: 'graph-by-analisis',
  templateUrl: './graph-by-analisis.component.html',
  styleUrls: ['./graph-by-analisis.component.css'],
})
export class GraphByAnalisisComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private serviceanalisis: CallsAnalisisService,
    private dialogService: DialogVestaService
  ) {}
  @ViewChild('graphCalorInfo', { static: false })
  graphCalorInfo: GraphGridSwitchComponent;
  @ViewChild('graph', { static: false }) chartContainer: ElementRef;
  //@Input() series: Array<any> = [];
  @Input() categories: Array<string> = [];
  @Output() reload = new EventEmitter<boolean>();

  public chart: Chart;

  public isComparacionOrExclusion: boolean = null;
  public switch1: boolean = true;
  public switchTablaOrGraph: boolean = true;
  public graphDelta: boolean = false;
  public switchUsdGestion: boolean = true;
  public unlock: boolean = false;

  public maxTiempoProceso = 0;
  public minTiempoProceso = 0;
  public indexResetDias = 0;

  public UsuarioId: string = '';
  public costostitle = 'Costos USD';
  public titleButton: string = 'Reset';

  public listCostosFactura: Array<CorteDiarioModel.CostosFactura> = [];
  public listCostosLog: Array<CorteDiarioModel.CostosLogisticos> = [];
  public groupByCategory: CorteDiarioModel.ListCategories[] = [];
  public groupByCategoryCL: CorteDiarioModel.ListCategories[] = [];

  public listGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;
  public AlllistGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;
  public PlantillaSelected: CorteDiarioModel.PlantillasIndex;
  public listrutasXUsuario: Array<CorteDiarioModel.RutasByUsuario> = [];
  public listHistoricoBytemplate: Array<CorteDiarioModel.HistoricoTemplate> =
    [];

  public BoxPlotData: Array<number[]> = [];
  public BoxPlotDataDelta: Array<number[]> = [];
  public lineGraphData: Array<number> = [];
  public lineGraphDataTons: Array<number> = [];
  public scatterData: Array<number> = [];
  public series: Array<any> = [];

  ngOnInit(): void {
    this.categories = [
      'Origen',
      'Transito1',
      'Frontera',
      'Transito2',
      'Destino',
    ];
    //this.dataprueba();
  }

  ngData() {
    this.GetHistoricoDias(this.PlantillaSelected.Id);
  }

  EventRegenerateGraph() {
    console.log('redraaaw');
    // this.chart.ref.destroy()
    setTimeout(() => {
      this.chart.ref.setSize(
        this.chartContainer.nativeElement.offsetWidth,
        350,
        false
      );
      this.chart.ref.redraw(true);
    }, 1000);
  }

  EventObtenerGestiones(
    event: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
    mainGraph: boolean,
    max?: number,
    min?: number
  ) {
    console.log(event);

    if (max != null && max != undefined && min != null && min != undefined) {
      max = parseFloat(max?.toFixed(2));
      min = parseFloat(min?.toFixed(2));
    }

    //Al levantar el modal
    setTimeout(() => {
      this.minTiempoProceso =
        min != undefined && min != null
          ? min
          : this.PlantillaSelected != null &&
            this.PlantillaSelected != undefined
          ? this.PlantillaSelected.DiaMin != null &&
            this.PlantillaSelected.DiaMin != 0
            ? this.PlantillaSelected.DiaMin
            : parseFloat(
                Enumerable.from(event)
                  .min((s) => s.TiempoProceso)
                  .toFixed(2)
              )
          : parseFloat(
              Enumerable.from(event)
                .min((s) => s.TiempoProceso)
                .toFixed(2)
            );
      //this.minTiempoProceso = 0;
      this.maxTiempoProceso =
        max != undefined && max != null
          ? max
          : this.PlantillaSelected != null &&
            this.PlantillaSelected != undefined
          ? this.PlantillaSelected.DiaMax != null &&
            this.PlantillaSelected.DiaMax != 0
            ? this.PlantillaSelected.DiaMax
            : parseFloat(
                Enumerable.from(event)
                  .max((s) => s.TiempoProceso)
                  .toFixed(2)
              )
          : parseFloat(
              Enumerable.from(event)
                .max((s) => s.TiempoProceso)
                .toFixed(2)
            );

      //Aqui dejamos todas las gestiones que retorna el metodo
      //porque al comparar o excluir , no se cambiaran los grids.
      //this.listGestiones = [...event];
      //console.log(this.listGestiones);

      this.GenerateDataFacturacion(event);
      this.GenerateDataCLogisticos(event);
      this.EventGenerateGRaph(event);
    }, 2000);
  }

  EventGenerateGRaph(
    event: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>
  ) {
    this.scatterData = []; //Tiempos meta

    //crear el grafico de boxplot
    //obtener todos los origenes de todas las gestiones
    let arrayBoxPlot: Array<number[]> = [];
    let arrayBoxPlotDelta: Array<number[]> = [];

    let arrayOrigen = [0, 0, 0, 0, 0];
    let arrayOrigenDelta = [0, 0, 0, 0, 0];

    let origenArray1 = Enumerable.from(event)
      .select((s) => s.Segmentacion.Origen)
      .where((s) => s?.SitioActual == true)
      .toArray();
    console.log(origenArray1);
    let cantidadTons = 0;
    cantidadTons = parseFloat(
      Enumerable.from(event)
        .where((s) => s.Segmentacion.Origen?.SitioActual == true)
        .selectMany((s) => s.CostosFacturacion)
        .sum((s) => s.TotalPesoToneladas)
        .toFixed(2)
    );
    this.lineGraphDataTons.push(parseFloat(cantidadTons.toFixed(2)));
    cantidadTons = parseFloat(
      Enumerable.from(event)
        .where((s) =>
          s.Segmentacion.TransitoUno.some(
            (t) => t.SitioActual == true || t?.SegmentoActual == true
          )
        )
        .selectMany((s) => s.CostosFacturacion)
        .sum((s) => s.TotalPesoToneladas)
        .toFixed(2)
    );
    this.lineGraphDataTons.push(parseFloat(cantidadTons.toFixed(2)));
    cantidadTons = parseFloat(
      Enumerable.from(event)
        .where((s) =>
          s.Segmentacion.Frontera?.some((t) => t.SitioActual == true)
        )
        .selectMany((s) => s.CostosFacturacion)
        .sum((s) => s.TotalPesoToneladas)
        .toFixed(2)
    );
    this.lineGraphDataTons.push(parseFloat(cantidadTons.toFixed(2)));
    cantidadTons = parseFloat(
      Enumerable.from(event)
        .where((s) =>
          s.Segmentacion.TransitoDos.some(
            (t) => t.SitioActual == true || t?.SegmentoActual == true
          )
        )
        .selectMany((s) => s.CostosFacturacion)
        .sum((s) => s.TotalPesoToneladas)
        .toFixed(2)
    );
    this.lineGraphDataTons.push(parseFloat(cantidadTons.toFixed(2)));
    cantidadTons = parseFloat(
      Enumerable.from(event)
        .where((s) =>
          s.Segmentacion?.Destino?.some((t) => t.SitioActual == true)
        )
        .selectMany((s) => s.CostosFacturacion)
        .sum((s) => s.TotalPesoToneladas)
        .toFixed(2)
    );
    this.lineGraphDataTons.push(parseFloat(cantidadTons.toFixed(2)));

    if (
      origenArray1 != undefined &&
      origenArray1.length > 0 &&
      origenArray1.every((s) => s != undefined)
    ) {
      const origenArray = origenArray1.filter(
        (item) => item !== null || item !== undefined
      );

      let tiempoMetaDiasOrigen = parseFloat(
        (
          Enumerable.from(event)
            .select((s) => s.Segmentacion.Origen)
            .sum((s) => s.TiempoMetaEnHoras) / 24
        ).toFixed(2)
      ); //Tiempo meta
      this.scatterData.push(
        parseFloat((tiempoMetaDiasOrigen / event.length).toFixed(2))
      );
      console.log('.........................meta origen');
      console.log(tiempoMetaDiasOrigen);

      let arraySortOrigen = Enumerable.from(origenArray)
        .select((s) => s.TiempoDias)
        .toArray()
        .sort((a, b) => a - b);
      console.log('arraySortOrigen' + arraySortOrigen);

      let arraySortOrigenDelta = Enumerable.from(origenArray)
        .select((s) => s.TiempoDelta)
        .toArray()
        .sort((a, b) => a - b);

      let menorOrigen = parseFloat(
        Enumerable.from(origenArray)
          .min((s) => s.TiempoDias)
          .toFixed(2)
      ); //menor
      console.log(menorOrigen);
      arrayOrigen[0] = menorOrigen;

      arrayOrigen[1] = this.GenerateQuartil(arraySortOrigen, 1);

      arrayOrigen[2] = this.GenerateQuartil(arraySortOrigen, 2);

      arrayOrigen[3] = this.GenerateQuartil(arraySortOrigen, 3);

      let mayorOrigen = parseFloat(
        Enumerable.from(origenArray)
          .max((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(mayorOrigen);
      arrayOrigen[4] = mayorOrigen;

      //Para Delta
      let menorOrigenDelta = parseFloat(
        Enumerable.from(origenArray)
          .min((s) => s.TiempoDelta)
          .toFixed(2)
      ); //menor

      arrayOrigenDelta[0] = menorOrigenDelta;

      arrayOrigenDelta[1] = this.GenerateQuartil(arraySortOrigenDelta, 1);

      arrayOrigenDelta[2] = this.GenerateQuartil(arraySortOrigenDelta, 2);

      arrayOrigenDelta[3] = this.GenerateQuartil(arraySortOrigenDelta, 3);

      let mayorOrigenDelta = parseFloat(
        Enumerable.from(origenArray)
          .max((s) => s.TiempoDelta)
          .toFixed(2)
      );
      arrayOrigenDelta[4] = mayorOrigenDelta;
    } else {
      //no hay gestiones, poner en cero los puntos metas
      this.scatterData.push(0);
    }

    arrayBoxPlot.push(arrayOrigen);
    arrayBoxPlotDelta.push(arrayOrigen);
    //[10,]

    let arrayTransito1 = [0, 0, 0, 0, 0];
    let arrayTransito1Delta = [0, 0, 0, 0, 0];

    let transito1Array1 = Enumerable.from(event)
      .selectMany((s) => s.Segmentacion.TransitoUno)
      .where((s) => s?.SitioActual == true || s?.SegmentoActual == true)
      .toArray();
    console.log(transito1Array1);
    const transito1Array = transito1Array1.filter(
      (item) => item !== null && item !== undefined
    );

    if (
      transito1Array != undefined &&
      transito1Array.length > 0 &&
      transito1Array.every((s) => s != undefined)
    ) {
      let tiempoMetaDiasT1 = parseFloat(
        (
          Enumerable.from(event)
            .selectMany((s) => s.Segmentacion.TransitoUno)
            .sum((s) => s.TiempoMetaEnHoras) / 24
        ).toFixed(2)
      );
      this.scatterData.push(
        parseFloat((tiempoMetaDiasT1 / event.length).toFixed(2))
      );
      console.log('.........................meta t1');
      console.log(tiempoMetaDiasT1);

      let arraySortT1 = Enumerable.from(transito1Array)
        .select((s) => s.TiempoDias)
        .toArray()
        .sort((a, b) => a - b);
      console.log('arraySortT1' + arraySortT1);
      let arraySortT1Delts = Enumerable.from(transito1Array)
        .select((s) => s.TiempoDelta)
        .toArray()
        .sort((a, b) => a - b);

      let menorOrigenT1 = parseFloat(
        Enumerable.from(transito1Array)
          .min((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(menorOrigenT1);
      arrayTransito1[0] = menorOrigenT1;

      arrayTransito1[1] = this.GenerateQuartil(arraySortT1, 1);

      arrayTransito1[2] = this.GenerateQuartil(arraySortT1, 2);

      arrayTransito1[3] = this.GenerateQuartil(arraySortT1, 3);

      let mayorOrigenT1 = parseFloat(
        Enumerable.from(transito1Array)
          .max((s) => s.TiempoDias)
          .toFixed(2)
      );
      arrayTransito1[4] = mayorOrigenT1;

      //Para Delta
      let menorOrigenDelta = parseFloat(
        Enumerable.from(transito1Array)
          .min((s) => s.TiempoDelta)
          .toFixed(2)
      ); //menor

      arrayTransito1Delta[0] = menorOrigenDelta;

      arrayTransito1Delta[1] = this.GenerateQuartil(arraySortT1Delts, 1);

      arrayTransito1Delta[2] = this.GenerateQuartil(arraySortT1Delts, 2);

      arrayTransito1Delta[3] = this.GenerateQuartil(arraySortT1Delts, 3);

      let mayorOrigenDelta = parseFloat(
        Enumerable.from(transito1Array)
          .max((s) => s.TiempoDelta)
          .toFixed(2)
      );
      arrayTransito1Delta[4] = mayorOrigenDelta;
    } else {
      //no hay gestiones, poner en cero los puntos metas
      this.scatterData.push(0);
    }

    arrayBoxPlot.push(arrayTransito1);
    arrayBoxPlotDelta.push(arrayTransito1Delta);

    let arrayFrontera = [0, 0, 0, 0, 0];
    let arrayFronteraDelta = [0, 0, 0, 0, 0];

    let fronteraArray1 = Enumerable.from(event)
      .selectMany((s) => s.Segmentacion.Frontera)
      .where((s) => s?.SitioActual == true)
      .toArray();
    //let fronteraArray1 = Enumerable.from(event).select(s => s.Segmentacion.Frontera).where(s => s?.SitioActual==true).toArray();
    console.log(fronteraArray1);
    const fronteraArray = fronteraArray1.filter(
      (item) => item !== null || item !== undefined
    );

    if (
      fronteraArray != undefined &&
      fronteraArray.length > 0 &&
      fronteraArray.every((s) => s != undefined)
    ) {
      //let tiempoMetaDiasF = parseFloat((Enumerable.from(event).select(s => s.Segmentacion.Frontera).sum(s => s.TiempoMetaEnHoras) / 24).toFixed(2));
      let tiempoMetaDiasF = parseFloat(
        (
          Enumerable.from(event)
            .selectMany((s) => s.Segmentacion.Frontera)
            .sum((s) => s.TiempoMetaEnHoras) / 24
        ).toFixed(2)
      );

      console.log('.........................meta frontera');
      console.log(tiempoMetaDiasF);
      this.scatterData.push(
        parseFloat((tiempoMetaDiasF / event.length).toFixed(2))
      );

      let arraySortFrontera = Enumerable.from(fronteraArray)
        .select((s) => s.TiempoDias)
        .toArray()
        .sort((a, b) => a - b);
      console.log('arraySortFrontera' + arraySortFrontera);
      let arraySortFronteraDelta = Enumerable.from(fronteraArray)
        .select((s) => s.TiempoDelta)
        .toArray()
        .sort((a, b) => a - b);

      let menorOrigenF = parseFloat(
        Enumerable.from(fronteraArray)
          .min((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(menorOrigenF);
      console.log(arraySortFrontera);
      arrayFrontera[0] = menorOrigenF;

      arrayFrontera[1] = this.GenerateQuartil(arraySortFrontera, 1);

      arrayFrontera[2] = this.GenerateQuartil(arraySortFrontera, 2);

      arrayFrontera[3] = this.GenerateQuartil(arraySortFrontera, 3);

      let mayorOrigenF = parseFloat(
        Enumerable.from(fronteraArray)
          .max((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(mayorOrigenF);
      arrayFrontera[4] = mayorOrigenF;

      //Para Delta
      let menorOrigenDelta = parseFloat(
        Enumerable.from(fronteraArray)
          .min((s) => s.TiempoDelta)
          .toFixed(2)
      ); //menor

      arrayFronteraDelta[0] = menorOrigenDelta;

      arrayFronteraDelta[1] = this.GenerateQuartil(arraySortFronteraDelta, 1);

      arrayFronteraDelta[2] = this.GenerateQuartil(arraySortFronteraDelta, 2);

      arrayFronteraDelta[3] = this.GenerateQuartil(arraySortFronteraDelta, 3);

      let mayorOrigenDelta = parseFloat(
        Enumerable.from(fronteraArray)
          .max((s) => s.TiempoDelta)
          .toFixed(2)
      );
      arrayFronteraDelta[4] = mayorOrigenDelta;
    } else {
      //no hay gestiones, poner en cero los puntos metas
      this.scatterData.push(0);
    }

    arrayBoxPlot.push(arrayFrontera);
    arrayBoxPlotDelta.push(arrayFronteraDelta);

    let arrayTransito2 = [0, 0, 0, 0, 0];
    let arrayTransito2Delta = [0, 0, 0, 0, 0];

    let transito2Array1 = Enumerable.from(event)
      .selectMany((s) => s.Segmentacion.TransitoDos)
      .where((s) => s?.SitioActual == true || s?.SegmentoActual == true)
      .toArray();
    console.log(transito2Array1);
    const transito2Array = transito2Array1.filter(
      (item) => item !== null || item !== undefined
    );

    if (
      transito2Array != undefined &&
      transito2Array.length > 0 &&
      transito2Array.every((s) => s != undefined)
    ) {
      let tiempoMetaDiasT2 = parseFloat(
        (
          Enumerable.from(event)
            .selectMany((s) => s.Segmentacion.TransitoDos)
            .sum((s) => s.TiempoMetaEnHoras) / 24
        ).toFixed(2)
      );
      this.scatterData.push(
        parseFloat((tiempoMetaDiasT2 / event.length).toFixed(2))
      );
      console.log('.........................meta t2');
      console.log(tiempoMetaDiasT2);
      console.log(
        Enumerable.from(event)
          .selectMany((s) => s.Segmentacion.TransitoDos)
          .toArray()
      );

      let arraySortT2 = Enumerable.from(transito2Array)
        .select((s) => s.TiempoDias)
        .toArray()
        .sort((a, b) => a - b);
      console.log('arraySortT2' + arraySortT2);
      let arraySortT2Delta = Enumerable.from(transito2Array)
        .select((s) => s.TiempoDelta)
        .toArray()
        .sort((a, b) => a - b);

      let menorOrigenT2 = parseFloat(
        Enumerable.from(transito2Array)
          .min((s) => s.TiempoDias)
          .toFixed(2)
      );

      console.log(menorOrigenT2);
      arrayTransito2[0] = menorOrigenT2;

      arrayTransito2[1] = this.GenerateQuartil(arraySortT2, 1);

      arrayTransito2[2] = this.GenerateQuartil(arraySortT2, 2);

      arrayTransito2[3] = this.GenerateQuartil(arraySortT2, 3);

      let mayorOrigenT2 = parseFloat(
        Enumerable.from(transito2Array)
          .max((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(mayorOrigenT2);
      arrayTransito2[4] = mayorOrigenT2;

      //Para Delta
      let menorOrigenDelta = parseFloat(
        Enumerable.from(transito2Array)
          .min((s) => s.TiempoDelta)
          .toFixed(2)
      ); //menor

      arrayTransito2Delta[0] = menorOrigenDelta;

      arrayTransito2Delta[1] = this.GenerateQuartil(arraySortT2Delta, 1);

      arrayTransito2Delta[2] = this.GenerateQuartil(arraySortT2Delta, 2);

      arrayTransito2Delta[3] = this.GenerateQuartil(arraySortT2Delta, 3);

      let mayorOrigenDelta = parseFloat(
        Enumerable.from(transito2Array)
          .max((s) => s.TiempoDelta)
          .toFixed(2)
      );
      arrayTransito2Delta[4] = mayorOrigenDelta;
    } else {
      //no hay gestiones, poner en cero los puntos metas
      this.scatterData.push(0);
    }

    arrayBoxPlot.push(arrayTransito2);
    arrayBoxPlotDelta.push(arrayTransito2Delta);

    let arrayDestino = [0, 0, 0, 0, 0];
    let arrayDestinoDelta = [0, 0, 0, 0, 0];

    //let destinosArray1 = Enumerable.from(event).select(s => s.Segmentacion.Destino).where(s => s?.SitioActual == true).toArray();
    let destinosArray1 = Enumerable.from(event)
      .selectMany((s) => s.Segmentacion.Destino)
      .where((s) => s?.SitioActual == true)
      .toArray();

    console.log(destinosArray1);

    if (
      destinosArray1 != undefined &&
      destinosArray1.length > 0 &&
      destinosArray1.every((s) => s != undefined)
    ) {
      const destinosArray = destinosArray1.filter(
        (item) => item !== null && item !== undefined
      );
      console.log(event);

      //let destinosEveryGestiones = Enumerable.from(event).where(s => s.Segmentacion?.Destino?.TiempoMetaEnHoras != undefined).select(s => s.Segmentacion?.Destino).toArray();
      let destinosEveryGestiones = Enumerable.from(event)
        .selectMany((s) => s.Segmentacion?.Destino)
        .where((s) => s.TiempoMetaEnHoras != undefined)
        .toArray();
      console.log(destinosEveryGestiones);

      let tiempoMetaDiasDestino = parseFloat(
        (
          Enumerable.from(destinosEveryGestiones).sum(
            (s) => s.TiempoMetaEnHoras
          ) / 24
        ).toFixed(2)
      );

      this.scatterData.push(
        parseFloat((tiempoMetaDiasDestino / event.length).toFixed(2))
      );
      console.log('.........................meta dest');
      console.log(tiempoMetaDiasDestino);

      let arraySortDestino = Enumerable.from(destinosArray)
        .select((s) => s.TiempoDias)
        .toArray()
        .sort((a, b) => a - b);
      console.log('destino' + arraySortDestino);
      let arraySortDestinoDelta = Enumerable.from(destinosArray)
        .select((s) => s.TiempoDelta)
        .toArray()
        .sort((a, b) => a - b);

      let menorOrigenD = parseFloat(
        Enumerable.from(destinosArray)
          .min((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(menorOrigenD);
      arrayDestino[0] = menorOrigenD;

      arrayDestino[1] = this.GenerateQuartil(arraySortDestino, 1);

      arrayDestino[2] = this.GenerateQuartil(arraySortDestino, 2);

      arrayDestino[3] = this.GenerateQuartil(arraySortDestino, 3);

      let mayorOrigenD = parseFloat(
        Enumerable.from(destinosArray)
          .max((s) => s.TiempoDias)
          .toFixed(2)
      );
      console.log(mayorOrigenD);
      arrayDestino[4] = mayorOrigenD;

      //Para Delta
      let menorOrigenDelta = parseFloat(
        Enumerable.from(destinosArray)
          .min((s) => s.TiempoDelta)
          .toFixed(2)
      ); //menor

      arrayDestinoDelta[0] = menorOrigenDelta;

      arrayDestinoDelta[1] = this.GenerateQuartil(arraySortDestinoDelta, 1);

      arrayDestinoDelta[2] = this.GenerateQuartil(arraySortDestinoDelta, 2);

      arrayDestinoDelta[3] = this.GenerateQuartil(arraySortDestinoDelta, 3);

      let mayorOrigenDelta = Enumerable.from(destinosArray).max(
        (s) => s.TiempoDelta
      );
      arrayDestinoDelta[4] = parseFloat(mayorOrigenDelta.toFixed(2));
    } else {
      //no hay gestiones, poner en cero los puntos metas
      this.scatterData.push(0);
    }

    arrayBoxPlot.push(arrayDestino);
    arrayBoxPlotDelta.push(arrayDestinoDelta);

    console.log(arrayBoxPlot);

    this.BoxPlotData = arrayBoxPlot;
    this.BoxPlotDataDelta = arrayBoxPlotDelta;

    if (this.listCostosFactura.length > 0 && this.listCostosLog.length > 0) {
      let origenSum = parseFloat(
        (
          this.listCostosFactura[0].Origen + this.listCostosLog[0].Origen
        ).toFixed(2)
      );
      let TransitoUnoSum = parseFloat(
        (
          this.listCostosFactura[0].TransitoUno +
          this.listCostosLog[0].TransitoUno
        ).toFixed(2)
      );
      let FronteraSum = parseFloat(
        (
          this.listCostosFactura[0].Frontera + this.listCostosLog[0].Frontera
        ).toFixed(2)
      );
      let TransitoDosSum = parseFloat(
        (
          this.listCostosFactura[0].TransitoDos +
          this.listCostosLog[0].TransitoDos
        ).toFixed(2)
      );
      let DestinoSum = parseFloat(
        (
          this.listCostosFactura[0].Destino + this.listCostosLog[0].Destino
        ).toFixed(2)
      );

      this.lineGraphData = [
        origenSum,
        TransitoUnoSum,
        FronteraSum,
        TransitoDosSum,
        DestinoSum,
      ];
    }

    console.log(this.scatterData);

    this.initChart();
  }

  GenerateQuartil(arraySort: number[], tipoQuartil: number) {
    let QOrigenIndex =
      tipoQuartil == 1
        ? (arraySort.length + 1) / 4
        : tipoQuartil == 2
        ? (arraySort.length + 1) / 2
        : ((arraySort.length + 1) * 3) / 4;

    let QOrigenVal = Number.isInteger(QOrigenIndex); //verificar si es decimal o entero
    let Quartil = QOrigenVal
      ? arraySort[QOrigenIndex - 1] //es entero
      : (arraySort[Math.floor(QOrigenIndex) - 1] +
          arraySort[Math.floor(QOrigenIndex)]) /
        2; //elijo el anterior y siguiente y divido
    //: (arraySort[Math.floor(QOrigenIndex) ] + arraySort[Math.floor(QOrigenIndex) + 1]) / 2//elijo el anterior y siguiente y divido

    console.log(Quartil + '  .... ' + tipoQuartil);
    return Number.isNaN(Quartil) || Quartil == undefined
      ? 0
      : parseFloat(Quartil.toFixed(2));
  }

  GenerateDataFacturacion(
    event: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>
  ) {
    this.listCostosFactura = [];
    if (event.length > 0) {
      let sumFacturaOrigen = 0;
      let sumFacturaT1 = 0;
      let sumFacturaFrontera = 0;
      let sumFacturaT2 = 0;
      let sumFacturaDestino = 0;

      let sumGestionesOrigen = 0;
      let sumGestionesT1 = 0;
      let sumGestionesFrontera = 0;
      let sumGestionesT2 = 0;
      let sumGestionesDestino = 0;

      let sumFacTonOrigen = 0;
      let sumFacTonT1 = 0;
      let sumFacTonFrontera = 0;
      let sumFacTonT2 = 0;
      let sumFacTonDestino = 0;

      this.lineGraphDataTons = [];
      this.groupByCategory = [];

      event.forEach((gestion) => {
        if (gestion.Segmentacion.Origen?.SitioActual == true) {
          sumFacturaOrigen += parseFloat(
            Enumerable.from(gestion.CostosFacturacion)
              .sum((s) => s.ValorTotalUSD)
              .toFixed(2)
          );
          sumGestionesOrigen +=
            gestion.Estado.DisplayName == 'Finalizado' &&
            gestion.GestionesHijas.length > 0
              ? gestion.GestionesHijas.length
              : 1; //aqui se acumula el valor de una gestion a la cat
          this.GenerateCalculosXSegFactura(gestion, 'Origen');
        } else if (
          gestion.Segmentacion.TransitoUno.some(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          )
        ) {
          sumFacturaT1 += parseFloat(
            Enumerable.from(gestion.CostosFacturacion)
              .sum((s) => s.ValorTotalUSD)
              .toFixed(2)
          );
          sumGestionesT1 +=
            gestion.Estado.DisplayName == 'Finalizado' &&
            gestion.GestionesHijas.length > 0
              ? gestion.GestionesHijas.length
              : 1; //aqui se acumula el valor de una gestion a la cat;

          this.GenerateCalculosXSegFactura(gestion, 'TransitoUno');
        } else if (
          gestion.Segmentacion.Frontera?.some((st1) => st1.SitioActual == true)
        ) {
          sumFacturaFrontera += parseFloat(
            Enumerable.from(gestion.CostosFacturacion)
              .sum((s) => s.ValorTotalUSD)
              .toFixed(2)
          );
          sumGestionesFrontera +=
            gestion.Estado.DisplayName == 'Finalizado' &&
            gestion.GestionesHijas.length > 0
              ? gestion.GestionesHijas.length
              : 1; //aqui se acumula el valor de una gestion a la cat;

          console.log(sumFacturaFrontera);
          this.GenerateCalculosXSegFactura(gestion, 'Frontera');
        } else if (
          gestion.Segmentacion.TransitoDos.some(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          )
        ) {
          sumFacturaT2 += parseFloat(
            Enumerable.from(gestion.CostosFacturacion)
              .sum((s) => s.ValorTotalUSD)
              .toFixed(2)
          );
          sumGestionesT2 +=
            gestion.Estado.DisplayName == 'Finalizado' &&
            gestion.GestionesHijas.length > 0
              ? gestion.GestionesHijas.length
              : 1; //aqui se acumula el valor de una gestion a la cat;
          console.log('sumFacturaT2', sumFacturaT2);

          this.GenerateCalculosXSegFactura(gestion, 'TransitoDos');
        } else if (
          gestion.Segmentacion.Destino?.some((st1) => st1.SitioActual == true)
        ) {
          sumFacturaDestino += parseFloat(
            Enumerable.from(gestion.CostosFacturacion)
              .sum((s) => s.ValorTotalUSD)
              .toFixed(2)
          );
          sumGestionesDestino +=
            gestion.Estado.DisplayName == 'Finalizado' &&
            gestion.GestionesHijas.length > 0
              ? gestion.GestionesHijas.length
              : 1; //aqui se acumula el valor de una gestion a la cat;
          console.log('sumGestionesDestino', sumGestionesDestino);

          this.GenerateCalculosXSegFactura(gestion, 'Destino');
        }
      });

      //let USD = Enumerable.from(event).selectMany(s => s.CostosFacturacion).sum(s => s.ValorTotalUSD);
      let USD = parseFloat(
        Enumerable.from(this.groupByCategory)
          .sum((s) => s.USD)
          .toFixed(2)
      );

      this.listCostosFactura.push({
        Expandible: false,
        Volumen: event.length,
        USD: USD,
        Origen: sumFacturaOrigen,
        TransitoUno: sumFacturaT1,
        Frontera: sumFacturaFrontera,
        TransitoDos: sumFacturaT2,
        Destino: sumFacturaDestino,
        OrigenGestiones: sumGestionesOrigen,
        TransitoUnoGestiones: sumGestionesT1,
        FronteraGestiones: sumGestionesFrontera,
        TransitoDosGestiones: sumGestionesT2,
        DestinoGestiones: sumGestionesDestino,
        ListCategorys: this.groupByCategory,
      });

      console.log(this.listCostosFactura);
    } else {
      this.listCostosFactura.push({
        Expandible: false,
        Volumen: event.length,
        USD: 0,
        Origen: 0,
        TransitoUno: 0,
        Frontera: 0,
        TransitoDos: 0,
        Destino: 0,
        OrigenGestiones: 0,
        TransitoUnoGestiones: 0,
        FronteraGestiones: 0,
        TransitoDosGestiones: 0,
        DestinoGestiones: 0,
        ListCategorys: [],
      });
    }
  }

  GenerateCalculosXSegFactura(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    campoACambiar: string
  ) {
    let contDetalllesFActuras = 0;
    let campoC = campoACambiar + 'Gestiones';
    if (gestion.CostosFacturacion.length === 0) {
      if (!this.groupByCategory.some((c) => c.Categoria === 'Sin Categoria')) {
        let categoria: CorteDiarioModel.ListCategories = {
          Expandible: false,
          Categoria: 'Sin Categoria',
          USD: 0,
          Origen: 0,
          TransitoUno: 0,
          Frontera: 0,
          TransitoDos: 0,
          Destino: 0,
          OrigenGestiones: 0,
          TransitoUnoGestiones: 0,
          FronteraGestiones: 0,
          TransitoDosGestiones: 0,
          DestinoGestiones: 0,
          Listdos: [],
        };

        categoria[campoACambiar] = 0; //aqui se acumula el valor al sku
        categoria['USD'] = 0;
        categoria[campoC] =
          gestion.Estado.DisplayName == 'Finalizado' &&
          gestion.GestionesHijas.length > 0
            ? gestion.GestionesHijas.length
            : 1; //aqui se acumula el valor de una gestion a la cat

        this.groupByCategory.push(categoria);
        console.log('this.groupByCategory', this.groupByCategory);
      } else {
        //acumular la cantidad en la categoria
        let indexCat = this.groupByCategory.findIndex(
          (s) => s.Categoria == 'Sin Categoria'
        );
        this.groupByCategory[indexCat][campoACambiar] += 0;
        this.groupByCategory[indexCat][campoC] +=
          contDetalllesFActuras == 0
            ? gestion.Estado.DisplayName == 'Finalizado' &&
              gestion.GestionesHijas.length > 0
              ? gestion.GestionesHijas.length
              : 1
            : 0; //se acumula una gestion

        console.log('this.groupByCategory', this.groupByCategory);
      }
    } else {
      gestion.CostosFacturacion.forEach((factura) => {
        factura.DetalleFactura.forEach((detalle) => {
          //corroboro si existe la categoria en el array
          if (
            !this.groupByCategory.some((s) => s.Categoria == detalle.Categoria)
          ) {
            //no existe
            //Agregar el sku
            let sku: CorteDiarioModel.ListDosExpandible = {
              Descripcion: detalle.ProductoDescripcion,
              USD: 0,
              Origen: 0,
              TransitoUno: 0,
              Frontera: 0,
              TransitoDos: 0,
              Destino: 0,
              OrigenGestiones: 0,
              TransitoUnoGestiones: 0,
              FronteraGestiones: 0,
              TransitoDosGestiones: 0,
              DestinoGestiones: 0,
            };

            sku[campoACambiar] = detalle.ValorTotal; //aqui se acumula el valor al sku
            sku['USD'] = detalle.ValorTotal;
            sku[campoC] =
              gestion.Estado.DisplayName == 'Finalizado' &&
              gestion.GestionesHijas.length > 0
                ? Enumerable.from(gestion.GestionesHijas)
                    .selectMany((s) => s.CostosFacturacion)
                    .selectMany((s) => s.DetalleFactura)
                    .where((s) => s.CodigoSku === detalle.CodigoSku)
                    .toArray().length
                : 1; //aqui se acumula el valor al sku

            let categoria: CorteDiarioModel.ListCategories = {
              Expandible: false,
              Categoria: detalle.Categoria,
              USD: 0,
              Origen: 0,
              TransitoUno: 0,
              Frontera: 0,
              TransitoDos: 0,
              Destino: 0,
              OrigenGestiones: 0,
              TransitoUnoGestiones: 0,
              FronteraGestiones: 0,
              TransitoDosGestiones: 0,
              DestinoGestiones: 0,
              Listdos: [sku],
            };

            categoria[campoACambiar] = detalle.ValorTotal; //aqui se acumula el valor al sku
            categoria['USD'] = detalle.ValorTotal;
            categoria[campoC] =
              gestion.Estado.DisplayName == 'Finalizado' &&
              gestion.GestionesHijas.length > 0
                ? gestion.GestionesHijas.length
                : 1; //aqui se acumula el valor de una gestion a la cat

            this.groupByCategory.push(categoria);
            console.log('this.groupByCategory', this.groupByCategory);
          } else {
            //acumular la cantidad en la categoria
            let indexCat = this.groupByCategory.findIndex(
              (s) => s.Categoria == detalle.Categoria
            );
            this.groupByCategory[indexCat][campoACambiar] += detalle.ValorTotal;
            this.groupByCategory[indexCat][campoC] +=
              contDetalllesFActuras == 0
                ? gestion.Estado.DisplayName == 'Finalizado' &&
                  gestion.GestionesHijas.length > 0
                  ? gestion.GestionesHijas.length
                  : 1
                : 0; //se acumula una gestion

            this.groupByCategory[indexCat].USD += detalle.ValorTotal;
            console.log('this.groupByCategory', this.groupByCategory);
            //buscar en las lista de  skus si ya existe, sino acumularlo
            if (
              !this.groupByCategory[indexCat].Listdos.some(
                (s) => s.Descripcion == detalle.ProductoDescripcion
              )
            ) {
              //no existe el sku,agregarlo
              let sku = {
                Descripcion: detalle.ProductoDescripcion,
                USD: 0,
                Origen: 0,
                TransitoUno: 0,
                Frontera: 0,
                TransitoDos: 0,
                Destino: 0,
                OrigenGestiones: 0,
                TransitoUnoGestiones: 0,
                FronteraGestiones: 0,
                TransitoDosGestiones: 0,
                DestinoGestiones: 0,
              };

              sku[campoACambiar] = detalle.ValorTotal;
              sku['USD'] = detalle.ValorTotal;
              sku[campoC] =
                gestion.Estado.DisplayName == 'Finalizado' &&
                gestion.GestionesHijas.length > 0
                  ? Enumerable.from(gestion.GestionesHijas)
                      .selectMany((s) => s.CostosFacturacion)
                      .selectMany((s) => s.DetalleFactura)
                      .where((s) => s.CodigoSku === detalle.CodigoSku)
                      .toArray().length
                  : 1; //aqui se acumula el valor al sku

              this.groupByCategory[indexCat].Listdos.push(sku);
            } else {
              // ya existe el sku
              let indexSku = this.groupByCategory[indexCat].Listdos.findIndex(
                (s) => s.Descripcion == detalle.ProductoDescripcion
              );
              if (indexSku != -1) {
                this.groupByCategory[indexCat].Listdos[indexSku][
                  campoACambiar
                ] += detalle.ValorTotal;
                this.groupByCategory[indexCat].Listdos[indexSku][campoC] +=
                  gestion.Estado.DisplayName == 'Finalizado' &&
                  gestion.GestionesHijas.length > 0
                    ? Enumerable.from(gestion.GestionesHijas)
                        .selectMany((s) => s.CostosFacturacion)
                        .selectMany((s) => s.DetalleFactura)
                        .where((s) => s.CodigoSku === detalle.CodigoSku)
                        .toArray().length
                    : 1;
                this.groupByCategory[indexCat].Listdos[indexSku].USD +=
                  detalle.ValorTotal;
              }
            }
          }

          //para manejar el num de gestiones a agregar a lac ategoria
          contDetalllesFActuras += 1;
        });
      });
    }
  }

  GenerateDataCLogisticos(
    event: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>
  ) {
    //COSTOS LOGISTCOS
    this.listCostosLog = [];
    this.groupByCategoryCL = [];

    let sumCostoOrigen = 0;
    let sumCostoT1 = 0;
    let sumCostoFrontera = 0;
    let sumCostoT2 = 0;
    let sumCostoDestino = 0;

    let categories: string[] = ['Category1', 'Category2', 'Category3'];

    event.forEach((item) => {
      event.forEach((s) => {
        s.CostosLogisticos.forEach((cat) => {
          const randomCategory =
            categories[Math.floor(Math.random() * categories.length)];
          if (
            cat.Categoria == '' ||
            cat.Categoria == null ||
            cat.Categoria == undefined
          ) {
            cat.Categoria = 'Sin Categoría';
          }
        });
      });
    });

    event.forEach((gestion) => {
      if (gestion.Segmentacion.Origen?.SitioActual == true) {
        sumCostoOrigen += parseFloat(
          Enumerable.from(gestion.CostosLogisticos)
            .sum((s) => s.ValorNew)
            .toFixed(2)
        );
        this.GenerateCalculosXSegmentoLogisticos(gestion, 'Origen');
      } else if (
        gestion.Segmentacion.TransitoUno.some(
          (st1) => st1.SegmentoActual == true || st1.SitioActual == true
        )
      ) {
        sumCostoT1 += parseFloat(
          Enumerable.from(gestion.CostosLogisticos)
            .sum((s) => s.ValorNew)
            .toFixed(2)
        );
        this.GenerateCalculosXSegmentoLogisticos(gestion, 'TransitoUno');
      } else if (
        gestion.Segmentacion.Frontera?.some((st1) => st1.SitioActual == true)
      ) {
        sumCostoFrontera += parseFloat(
          Enumerable.from(gestion.CostosLogisticos)
            .sum((s) => s.ValorNew)
            .toFixed(2)
        );
        this.GenerateCalculosXSegmentoLogisticos(gestion, 'Frontera');
      } else if (
        gestion.Segmentacion.TransitoDos.some(
          (st1) => st1.SegmentoActual == true || st1.SitioActual == true
        )
      ) {
        sumCostoT2 += parseFloat(
          Enumerable.from(gestion.CostosLogisticos)
            .sum((s) => s.ValorNew)
            .toFixed(2)
        );
        this.GenerateCalculosXSegmentoLogisticos(gestion, 'TransitoDos');
      } else if (
        gestion.Segmentacion.Destino?.some((st1) => st1.SitioActual == true)
      ) {
        sumCostoDestino += parseFloat(
          Enumerable.from(gestion.CostosLogisticos)
            .sum((s) => s.ValorNew)
            .toFixed(2)
        );
        this.GenerateCalculosXSegmentoLogisticos(gestion, 'Destino');
      }
    });

    let volumenCL = parseFloat(event.length.toFixed(2));
    let USDCL = Enumerable.from(event).sum((s) => s.CostoTotal);

    let costosL = Enumerable.from(event)
      .selectMany((s) => s.CostosLogisticos)
      .toArray();
    let sumCostos = parseFloat(
      Enumerable.from(costosL)
        .sum((s) => s.ValorNew)
        .toFixed(2)
    );

    this.listCostosLog.push({
      Volumen: volumenCL,
      USD: parseFloat(sumCostos.toFixed(2)),
      Origen: parseFloat(sumCostoOrigen.toFixed(2)),
      TransitoUno: parseFloat(sumCostoT1.toFixed(2)),
      Frontera: parseFloat(sumCostoFrontera.toFixed(2)),
      TransitoDos: parseFloat(sumCostoT2.toFixed(2)),
      Destino: parseFloat(sumCostoDestino.toFixed(2)),
      ListCategorys: this.groupByCategoryCL,
    });

    console.log('costos logiiiiiiiiiiiiiisticos ');
    console.log(this.listCostosLog);
  }

  GenerateCalculosXSegmentoLogisticos(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    campoACambiar: string
  ) {
    let campoC = campoACambiar + 'Gestiones';
    gestion.CostosLogisticos.forEach((cLog) => {
      //corroboro si existe la categoria en el array
      if (!this.groupByCategoryCL.some((s) => s.Categoria == cLog.Categoria)) {
        //no existe
        //Agregar el sku
        let itemDos: CorteDiarioModel.ListDosExpandible = {
          Descripcion: cLog.Descripcion,
          USD: 0,
          Origen: 0,
          TransitoUno: 0,
          Frontera: 0,
          TransitoDos: 0,
          Destino: 0,
          OrigenGestiones: 0,
          TransitoUnoGestiones: 0,
          FronteraGestiones: 0,
          TransitoDosGestiones: 0,
          DestinoGestiones: 0,
        };

        itemDos[campoACambiar] = cLog.ValorNew; //aqui se acumula el valor al sku
        itemDos['USD'] = cLog.ValorNew;
        itemDos[campoC] = 1; //aqui se acumula el valor al sku

        let categoria: CorteDiarioModel.ListCategories = {
          Expandible: false,
          Categoria: cLog.Categoria,
          USD: 0,
          Origen: 0,
          TransitoUno: 0,
          Frontera: 0,
          TransitoDos: 0,
          Destino: 0,
          OrigenGestiones: 0,
          TransitoUnoGestiones: 0,
          FronteraGestiones: 0,
          TransitoDosGestiones: 0,
          DestinoGestiones: 0,
          Listdos: [itemDos],
        };

        categoria[campoACambiar] = cLog.ValorNew; //aqui se acumula el valor al sku
        categoria['USD'] = cLog.ValorNew;
        categoria[campoC] = 1; //aqui se acumula el valor de una gestion a la cat

        this.groupByCategoryCL.push(categoria);
      } else {
        //acumular la cantidad en la categoria
        let indexCat = this.groupByCategoryCL.findIndex(
          (s) => s.Categoria == cLog.Categoria
        );
        this.groupByCategoryCL[indexCat][campoACambiar] += cLog.ValorNew;
        this.groupByCategoryCL[indexCat][campoC] += 1; //se acumula una gestion
        this.groupByCategoryCL[indexCat].USD += cLog.ValorNew;
        //buscar en las lista de  skus si ya existe, sino acumularlo
        if (
          !this.groupByCategoryCL[indexCat].Listdos.some(
            (s) => s.Descripcion == cLog.Descripcion
          )
        ) {
          //no existe el sku,agregarlo
          let itemDos = {
            Descripcion: cLog.Descripcion,
            USD: 0,
            Origen: 0,
            TransitoUno: 0,
            Frontera: 0,
            TransitoDos: 0,
            Destino: 0,
            OrigenGestiones: 0,
            TransitoUnoGestiones: 0,
            FronteraGestiones: 0,
            TransitoDosGestiones: 0,
            DestinoGestiones: 0,
          };

          itemDos[campoACambiar] = cLog.ValorNew;
          itemDos['USD'] = cLog.ValorNew;
          itemDos[campoC] += 1; //aqui se acumula el valor al sku

          this.groupByCategoryCL[indexCat].Listdos.push(itemDos);
        } else {
          // ya existe el sku
          let indexSku = this.groupByCategoryCL[indexCat].Listdos.findIndex(
            (s) => s.Descripcion == cLog.Descripcion
          );
          if (indexSku != -1) {
            this.groupByCategoryCL[indexCat].Listdos[indexSku][campoACambiar] +=
              cLog.ValorNew;
            this.groupByCategoryCL[indexCat].Listdos[indexSku][campoC] += 1;
            this.groupByCategoryCL[indexCat].Listdos[indexSku].USD +=
              cLog.ValorNew;
          }
        }
      }
    });
  }

  EventChangeRangeTiempoProceso(value: number, tipo: string) {
    //cambiar el rango del tiempo del proceso para cambiar la info del grafico
    if (tipo == 'max') {
      this.maxTiempoProceso = value;
    } else if (tipo == 'min') {
      this.minTiempoProceso = value;
    }
    let gestionesFiltradas = Enumerable.from(this.listGestiones)
      .where(
        (s) =>
          s.TiempoProceso >= this.minTiempoProceso &&
          s.TiempoProceso <= this.maxTiempoProceso
      )
      .toArray();

    this.EventObtenerGestiones(
      gestionesFiltradas,
      false,
      this.maxTiempoProceso,
      this.minTiempoProceso
    );
  }

  dataprueba() {
    //this.listCostosFactura.push(
    //  {
    //    Expandible: false, Volumen: 500, USD: '$15000', Origen: 50, Transito1: 100, Frontera: 250, Transito2: 50, Destino: 50,
    //    ListCategorys: [
    //      {
    //        Expandible: false, Categoria: 'M. Prima', USD: '$15000', Origen: 50, Transito1: 100, Frontera: 250, Transito2: 50, Destino: 50,
    //        Listdos: [
    //          { Descripcion: 'Papa', USD: 15000, Origen: 50, Transito1: 100, Frontera: 250, Transito2: 50, Destino: 50 },
    //          { Descripcion: 'Platano', USD: 15000, Origen: 50, Transito1: 100, Frontera: 250, Transito2: 50, Destino: 50 },
    //        ]
    //      }
    //    ]
    //  },
    //);
    //this.listCostosLog.push(
    //  { Volumen: 500, USD: '$5000', Origen: '$1000', Transito1: '$1000', Frontera: '$1000', Transito2: '$1000', Destino: '$1000' }
    //);
  }

  initChart() {
    const boxplotData = this.BoxPlotData;
    // Calcula el punto más alto para cada conjunto de datos
    //const scatterData = boxplotData.map((data, i) => {
    //  const maxPoint = Math.max(...data);
    //  const maxIndex = i;//data.indexOf(maxPoint);
    //  return { x: maxIndex, y: maxPoint + 40 };
    //});
    this.series = [];
    if (!this.graphDelta) {
      this.series.push(
        {
          name: 'Tiempo Proceso',
          type: 'boxplot',
          data: this.BoxPlotData,
          pointWidth: 30, // Ajusta el ancho de cada boxplot
          tooltip: {
            headerFormat: '<em>{point.key}</em><br/>',
          },
          zoomType: 'xy',
          //colorByPoint: true,
          //colors: ['#0072b2', '#0072b2', '#1fa187', '#0072b2', '#0072b2'],
        },
        {
          name: this.costostitle,
          type: 'line',
          data: !this.switch1 ? this.lineGraphDataTons : this.lineGraphData,
          yAxis: 1, // Asocia la serie al segundo eje Y
        },
        {
          name: 'Tiempo Meta',
          type: 'scatter',
          data: this.scatterData,
          marker: {
            symbol: 'circle',
            radius: 4,
            fillColor: 'blue',
          },
          tooltip: {
            pointFormat: 'Punto más alto: {point.y}',
          },
        }
      );
    } else {
      this.series.push({
        name: 'Tiempo Proceso',
        type: 'boxplot',
        data: this.BoxPlotDataDelta,
        pointWidth: 30, // Ajusta el ancho de cada boxplot
        tooltip: {
          headerFormat: '<em>{point.key}</em><br/>',
        },

        //colorByPoint: true,
        //colors: ['#0072b2', '#0072b2', '#1fa187', '#0072b2', '#0072b2'],
      });
    }

    this.chart = new Chart({
      chart: {
        //renderTo: 'graph' // Asegúrate de tener un elemento con id 'container' en tu HTML
        height: 350,
        //width: '100%'
        zoomType: 'y',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: this.categories,
      },
      yAxis: [
        {
          title: {
            text: '',
          },
          labels: {
            format: '{value}',
          },
        },
        {
          title: {
            text: '',
          },
          opposite: true, // Muestra el eje en el lado opuesto
          labels: {
            format: '{value}',
          },
        },
      ],
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        boxplot: {
          //colorByPoint: true,
          // colors: ['#0072b2', '#0072b2', '#1fa187', '#0072b2', '#0072b2'],
          fillColor: '#0072b2',
          zones: [
            {
              value: 1, // Valor de la primera categoría
              fillColor: '#0072b2', // Color de relleno para la zona inferior
            },
            {
              value: 2, // Valor de la tercera categoría
              fillColor: '#0072b2', // Color de relleno para la zona superior
            },
            {
              value: 3, // Valor de la tercera categoría
              fillColor: '#1fa187', // Color de relleno para la zona superior
            },
            {
              value: 4, // Valor de la tercera categoría
              fillColor: '#0072b2', // Color de relleno para la zona superior
            },
            {
              value: 5, // Valor de la tercera categoría
              fillColor: '#0072b2', // Color de relleno para la zona superior
            },
          ],
        },
        scatter: {
          tooltip: {
            headerFormat:
              '<span style="font-size:12px; font-weight: bold">Tiempo Meta:</span><br/><div> ' +
              ' <hr/>',

            pointFormatter: function () {
              var data: any = this.y;
              return '<b>' + data + '</b>' + '<br/>';
            },
            footerFormat: '</div>',
          },
        },
      },
      series: this.series,
    });
  }

  EventGraphDelta() {
    this.graphDelta = !this.graphDelta;
    this.initChart();
  }

  EventChangeUsdVol() {
    this.switch1 = !this.switch1;
    this.costostitle = this.switch1 ? 'Costos USD' : 'Toneladas';
    this.initChart();
  }

  EventChangeTableOrGraph() {
    //this.switchTablaOrGraph = !this.switchTablaOrGraph;
    //this.listCostosFactura[0].Expandible = this.listCostosFactura[0].Expandible == false ? true : this.listCostosFactura[0].Expandible;
    if (!this.switchTablaOrGraph) {
      setTimeout(() => {
        console.log(this.listGestiones);
        this.graphCalorInfo.listGestiones = this.listGestiones;
        this.graphCalorInfo.ngGenerateSectionInfoGraph();
      }, 1000);
    }
  }

  EventModalGestiones(tipo: string) {
    let gestionesFiltradas = Enumerable.from(this.listGestiones)
      .where(
        (s) =>
          s.TiempoProceso >= this.minTiempoProceso &&
          s.TiempoProceso <= this.maxTiempoProceso
      )
      .toArray();
    console.log('gestionesssssssssssssssss all');
    console.log(gestionesFiltradas);
    const dialogRef = this.dialog.open(ModalGestionesComponent, {
      width: '95%', // Ancho del diálogo
      height: '90%', // Alto del diálogo
      disableClose: true,
      data: {
        gestiones: gestionesFiltradas,
        tipo: tipo,
        usuarioId: this.UsuarioId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  EventModalcomparacion() {
    const dialogRef = this.dialog.open(ModalComparacionComponent, {
      width: '70%', // Ancho del diálogo
      height: '65%', // Alto del diálogo
      disableClose: true,
      data: {
        Allgestiones: this.AlllistGestiones,
        gestiones: this.listGestiones,
        plantillaSelected: this.PlantillaSelected,
        maxDias: this.maxTiempoProceso,
        mindias: this.minTiempoProceso,
        isComparacion: true,
        UsuarioId: this.UsuarioId,
        RutasXUsuario: Enumerable.from(this.listrutasXUsuario)
          .where((s) =>
            this.PlantillaSelected.TemplateRutas.some(
              (r) => r.Ruta.Id.toLowerCase() == s.Id.toLowerCase()
            )
          )
          .toArray(),
      },
      panelClass: 'dialog-comparacion1',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
      let reload = dialogRef.componentInstance.Reload;
      let rutasNew = dialogRef.componentInstance.newListrutasXUsuario;
      if (reload) {
        this.reload.emit(reload);
      }
      if (rutasNew?.length > 0) {
        this.listrutasXUsuario = rutasNew;
      }
    });
  }

  EventModalExlcusion() {
    const dialogRef = this.dialog.open(ModalGraphComparacionComponent, {
      width: '100%', // Ancho del diálogo
      height: '100%', // Alto del diálogo
      disableClose: true,
      data: {
        Allgestiones: this.AlllistGestiones,
        gestiones: this.listGestiones,
        maxDias: this.maxTiempoProceso,
        mindias: this.minTiempoProceso,
        isComparacion: false,
        listrutas: [],
        usuarioId: this.UsuarioId,
      },
      panelClass: 'dialog-comparacion2',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  EventActualizarDias() {
    if (
      this.minTiempoProceso != this.PlantillaSelected.DiaMin ||
      this.maxTiempoProceso != this.PlantillaSelected.DiaMax
    ) {
      let rutasWithSitios = [];
      let rutas = Enumerable.from(
        this.PlantillaSelected.TemplateRutas
      ).toArray();
      rutas.forEach((r) => {
        rutasWithSitios.push({
          rutaId: r.Ruta.Id,
          SitioReferenciaId: r.SitioReferenciaId,
        });
      });

      //let query = {
      //  "TemplateId": this.PlantillaSelected.Id,
      //  "Rutas": rutasWithSitios,
      //  "NombrePLantilla": this.PlantillaSelected.Nombre,
      //  "ModifiedBy": this.UsuarioId,
      //  "esPublica": this.PlantillaSelected.EsPublica,
      //  "favorita": this.PlantillaSelected.Favorita,
      //  "diaMin": this.PlantillaSelected.DiaMin != this.minTiempoProceso ? parseInt(this.minTiempoProceso.toString() ) : this.PlantillaSelected.DiaMin,
      //  "diaMax": this.PlantillaSelected.DiaMax != this.maxTiempoProceso ? parseInt(this.maxTiempoProceso.toString())  : this.PlantillaSelected.DiaMax,
      //}
      let query = {
        diaMin: this.minTiempoProceso,
        diaMax: this.maxTiempoProceso,
        templateId: this.PlantillaSelected.Id,
        createdById: this.UsuarioId,
      };
      this.serviceanalisis
        .PostHistoricoXPlantilla(query)
        .then((result) => {
          if (result) {
            this.PlantillaSelected.DiaMin =
              this.PlantillaSelected.DiaMin != this.minTiempoProceso
                ? parseInt(this.minTiempoProceso.toString())
                : this.PlantillaSelected.DiaMin;
            this.PlantillaSelected.DiaMax =
              this.PlantillaSelected.DiaMax != this.maxTiempoProceso
                ? parseInt(this.maxTiempoProceso.toString())
                : this.PlantillaSelected.DiaMax;
            this.dialogService.NotificacionSuccess(
              'Plantilla Actualizada correctamente'
            );
            this.GetHistoricoDias(this.PlantillaSelected.Id);
          }
        })
        .catch((e) => {
          this.dialogService.NotificacionAlert(e);
        });
    } else {
      this.dialogService.NotificacionInfo(
        'Cambiar al menos un tiempo de dias max o min.'
      );
    }
  }

  EventUnlock() {
    this.unlock = !this.unlock;
    if (this.unlock) {
      this.indexResetDias =
        this.listHistoricoBytemplate.length > 0
          ? this.listHistoricoBytemplate.length - 1
          : 0;
      this.titleButton = 'Deshacer';
    } else {
      this.titleButton = 'Reset';
    }
  }

  EventResetDias() {
    //si doy click
    if (this.unlock) {
      //rango de dias esta desbloqueado
      //entonces vuelvo al ultimo historico de la plantilla
      if (this.listHistoricoBytemplate.length > 0) {
        let ultimoHistorico = this.listHistoricoBytemplate[this.indexResetDias]; // Enumerable.from(this.listHistoricoBytemplate).lastOrDefault();
        console.log(ultimoHistorico);
        this.maxTiempoProceso = parseFloat(ultimoHistorico.DiaMax.toFixed(2));
        this.minTiempoProceso = parseFloat(ultimoHistorico.DiaMin.toFixed(2));

        let gestionesFiltradas = Enumerable.from(this.listGestiones)
          .where(
            (s) =>
              s.TiempoProceso >= this.minTiempoProceso &&
              s.TiempoProceso <= this.maxTiempoProceso
          )
          .toArray();

        this.EventObtenerGestiones(
          gestionesFiltradas,
          false,
          this.maxTiempoProceso,
          this.minTiempoProceso
        );

        this.indexResetDias =
          this.indexResetDias <= 0 ? 0 : this.indexResetDias - 1;
      }
    } else {
      //rango dias esta bloqueado
      //vuelvo al min y max de las gestiones

      //cambiar a todas las gestiones que retorno el metodo
      this.listGestiones = Enumerable.from(this.AlllistGestiones)
        .where(
          (s) =>
            this.PlantillaSelected.TemplateRutas.some(
              (r) => r.Ruta.Id == s.Ruta.Id
            ) == true
        )
        .toArray();
      this.maxTiempoProceso = parseFloat(
        Enumerable.from(this.listGestiones)
          .max((s) => s.TiempoProceso)
          .toFixed(2)
      );
      this.minTiempoProceso = parseFloat(
        Enumerable.from(this.listGestiones)
          .min((s) => s.TiempoProceso)
          .toFixed(2)
      );
      //send = true;

      let rutasWithSitios = [];
      let rutas = Enumerable.from(
        this.PlantillaSelected.TemplateRutas
      ).toArray();
      rutas.forEach((r) => {
        rutasWithSitios.push({
          rutaId: r.Ruta.Id,
          SitioReferenciaId: r.SitioReferenciaId,
        });
      });

      let query = {
        TemplateId: this.PlantillaSelected.Id,
        Rutas: rutasWithSitios,
        NombrePLantilla: this.PlantillaSelected.Nombre,
        ModifiedBy: this.UsuarioId,
        esPublica: this.PlantillaSelected.EsPublica,
        favorita: this.PlantillaSelected.Favorita,
        diaMin: this.minTiempoProceso,
        diaMax: this.maxTiempoProceso,
      };
      this.serviceanalisis
        .PostPlantillasUpdate(query)
        .then((result) => {
          if (result) {
            this.PlantillaSelected.DiaMin = this.minTiempoProceso;
            this.PlantillaSelected.DiaMax = this.maxTiempoProceso;
            this.dialogService.NotificacionSuccess('Plantilla Reseteada');
            this.EventObtenerGestiones(this.listGestiones, false);
          }
        })
        .catch((e) => {
          this.dialogService.NotificacionAlert(e);
        });
    }
  }

  GetHistoricoDias(plantillaId) {
    this.listHistoricoBytemplate = [];
    this.serviceanalisis
      .GetHistoricoDiasByPlantilla(plantillaId)
      .then((result) => {
        if (result) {
          this.listHistoricoBytemplate = result;
          this.indexResetDias =
            this.listHistoricoBytemplate.length > 0
              ? this.listHistoricoBytemplate.length - 1
              : 0;
        }
      })
      .catch((e) => {
        this.dialogService.NotificacionAlert(e);
      });
  }
}
